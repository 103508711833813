.loader-content-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin: 0 !important;
    border: 0 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    padding: 1em;
    height: 100%;
    width: 100%;
}
