:root {
    --background-color: #fbfbfb;
    --background-on-color: #000000;
    --text-high-emphasis-color: rgba(38, 50, 56, 0.9);
    --text-medium-emphasis-color: rgba(38, 50, 56, 0.6);
    --text-disabled-color: rgba(38, 50, 56, 0.38);
    --text-placeholder-color: #677074;
    --text-link-color: #001eff;
    --border-color: #dfe3e9;
    --primary-color: #2962ff;
    --primary-on-color: #ffffff;
    --primary-color-light: #7bc6d7;
    --primary-on-color-light: #05647a;
    --primary-color-dark: #006e97;
    --primary-on-color-dark: #ffffff;
    --secondary-color: #e62276;
    --secondary-on-color: #ffffff;
    --secondary-color-light: #f174a8;
    --secondary-on-color-light: #af004a;
    --secondary-color-dark: #af004a;
    --secondary-on-color-dark: #ffffff;
    --surface-color: #eeeeee;
    --surface-on-color: #1b1b1b;
    --surface-color-light: #ffffff;
    --surface-on-color-light: #1b1b1b;
    --surface-color-dark: #959595;
    --surface-on-color-dark: #ffffff;
    --error-color: #f44336;
    --error-on-color: #ffffff;
    --error-color-light: #f44336;
    --error-on-color-light: #ffffff;
    --error-color-dark: #b40000;
    --warning-color: #ff9800;
    --warning-on-color: #ffffff;
    --warning-color-light: #fff6c9;
    --warning-on-color-light: #000000;
    --success-color: #4caf50;
    --success-on-color: #ffffff;
    --success-color-dark: #006729;
    --success-color-light: #a9e2ac;
    --success-on-color-light: #000000;
    --success-background-color: #009773;
    --logo-background-color: #fff;
    --material-gray-1: #f2f2f2;
    --material-gray-2: #e0e0e0;
    --material-gray-3: #bdbdbd;
    --material-gray-4: #828282;
    --material-gray-5: #4f4f4f;
    --material-gray-6: #333333;
    --spacer: 8px;
    --tags-secondary-bg: #e1f6ff;
}
